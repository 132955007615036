import React, { useEffect, useState } from 'react';
import MainLayout from '../components/MainLayout';
import MapDroneimage from '../components/mapbox/MapDroneimage';
import axios from '../util/axios';

function Droneimage() {
  const [compare, setCompare] = useState(false);
  const [tilemaps, setTilemaps] = useState();
  const [fields, setFields] = useState();

  async function getTilemaps() {
    const response = await axios.get('/api/tilemaps');
    setTilemaps(response.data);
  }

  async function getFields() {
    const response = await axios.get('/api/fields');
    setFields(response.data);
  }

  useEffect(() => {
    getTilemaps();
    getFields();
  }, []);

  function updateCompare(c) {
    setCompare(c);
  }

  return (
    <MainLayout>
      <MapDroneimage tilemaps={tilemaps} fields={fields} compare={compare} updateCompare={updateCompare} main />

      {compare && <MapDroneimage tilemaps={tilemaps} fields={fields} compare={compare} updateCompare={updateCompare} />}
    </MainLayout>
  );
}

export default Droneimage;
