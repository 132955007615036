import { Col } from 'antd';
import React from 'react';
import MainLayout from '../components/MainLayout';
import { getUsername } from '../util/auth';

function Dashboard() {
  const iframeSrc = `/shiny/dashboard/${getUsername()}`;
  return (
    <MainLayout>
      <Col flex="auto">
        <iframe src={iframeSrc} title="dashboard" width="100%" height="100%" frameBorder="0" />
      </Col>
    </MainLayout>
  );
}

export default Dashboard;
