import React, { createContext, useState } from 'react';

const FieldMapContext = createContext();

const FieldMapProvider = (props) => {
  const [fieldMapContext, setFieldMapContext] = useState({
    map: undefined,
    mode: 'list', // list, save, history
    selectedField: undefined,
  });

  return (
    <FieldMapContext.Provider
      value={{
        ...fieldMapContext,
        setFieldMapContext: (data) => setFieldMapContext({ ...fieldMapContext, ...data }),
      }}
    >
      {props.children}
    </FieldMapContext.Provider>
  );
};

export { FieldMapContext, FieldMapProvider };
