/* eslint-disable no-underscore-dangle */
import mapboxgl from 'mapbox-gl';

import React, { useContext, useEffect, useRef } from 'react';

import SearchControl from './SearchControl';
import LayerControl from './LayerControl';
import GovFieldSelectControl from './GovFieldSelectControl';
import { defaultMapStyle, mapboxApiAccessToken } from './defaults';
import { FieldMapContext } from '../../context/FieldMapContext';
import { AppContext } from '../../context/AppContext';
import GovFieldAddressControl from './GovFieldAddressControl';
import { getUserConfigMapViewPort } from '../../util/auth';

function MapField() {
  const { mapViewPort, setMapViewPort } = useContext(AppContext);

  const mapRef = useRef(null);
  const { map, setFieldMapContext } = useContext(FieldMapContext);

  async function initializeMap() {
    mapboxgl.accessToken = mapboxApiAccessToken;
    const m = new mapboxgl.Map({
      container: mapRef.current,
      style: defaultMapStyle,
      ...(mapViewPort || getUserConfigMapViewPort()),
    });

    m.on('load', () => {
      setFieldMapContext({ map: m });
    });

    // m.on('click', (e) => {
    //   console.log('e.lngLat.wrap() :', e.lngLat.wrap());
    //   console.log('map.getZoom() :', m.getZoom());
    // });

    m.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right');
    m.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      }),
      'bottom-right'
    );
  }

  function onMoveEnd() {
    const viewPort = {
      center: map.getCenter(),
      zoom: map.getZoom(),
    };

    setMapViewPort(viewPort);
  }

  useEffect(() => {
    if (!map) {
      initializeMap();
    }

    if (map) {
      map.on('moveend', onMoveEnd);
    }

    return () => {
      if (map) {
        map.off('moveend', onMoveEnd);
      }
    };
  }, [map]);

  return (
    <>
      <div className="map-container" ref={mapRef}>
        <SearchControl map={map} />
        <LayerControl map={map} />
        <GovFieldSelectControl map={map} />
        <GovFieldAddressControl map={map} />
      </div>
    </>
  );
}

export default MapField;
