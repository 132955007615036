import { Col, Row, List } from 'antd';
import React, { useEffect, useState } from 'react';
import MainLayout from '../components/MainLayout';
import ReportListItem from '../components/ReportListItem';
import axios from '../util/axios';

function Report() {
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState();

  async function fetchReports() {
    const response = await axios.get('/api/reports');
    setReports(response.data);
  }

  useEffect(() => {
    fetchReports();
  }, []);

  function openReport(report) {
    setSelectedReport(report);
  }

  return (
    <MainLayout>
      <Col flex="260px" className="full-height shadow-right">
        <div className="layout">
          <div className="header bb-1">
            <Row>
              <Col offset={5} span={14} className="header-col">
                <h1>보고서 리스트</h1>
              </Col>
              <Col span={5} className="header-col" />
            </Row>
          </div>
          <div className="main">
            <List
              size="default"
              className="field-list"
              dataSource={reports}
              renderItem={(report) => (
                <ReportListItem report={report} openReport={openReport} selectedReport={selectedReport} />
              )}
            />
          </div>
        </div>
      </Col>
      <Col flex="auto" className="full-height">
        {selectedReport && (
          <div className="layout">
            <div className="header">
              <Row>
                <Col offset={5} span={14} className="header-col">
                  <h1>{selectedReport.name}</h1>
                </Col>
              </Row>
            </div>
            <div className="main" style={{ overflow: 'hidden' }}>
              {/* <Document file={`/api/report/${selectedReport.fileName}`}>
                <Page pageNumber={pageNumber} />
              </Document> */}
              <object data={`/api/report/${selectedReport.fileName}`} height="100%" width="100%" type="application/pdf">
                <embed
                  src={`/api/report/${selectedReport.fileName}`}
                  height="100%"
                  width="100%"
                  type="application/pdf"
                />
              </object>
            </div>
          </div>
        )}
      </Col>
    </MainLayout>
  );
}

export default Report;
