import { SearchOutlined } from '@ant-design/icons';
import { Input, message } from 'antd';
import axios from 'axios';
import React from 'react';

function SearchControl({ map }) {
  async function seachAndMove(keyword) {
    if (keyword.length < 1) {
      message.info('검색어는 1글자 이상이어야 합니다.');
      return;
    }

    const latlng = `${map.getCenter().lng.toFixed(4)}, ${map.getCenter().lat.toFixed(4)}`;

    const response = await axios({ method: 'POST', url: '/api/geocode', data: { keyword, latlng } });

    const { status } = response.data;
    if (status !== 'OK') {
      message.info(`검색 오류 : ${response.data.errorMessage}`);
      return;
    }

    const { totalCount } = response.data.meta;

    if (totalCount === 0) {
      message.info(`검색어 '${keyword}'의 주소 검색 결과가 없습니다.`);
    }

    if (totalCount > 0) {
      const firstAddress = response.data.addresses[0];

      if (firstAddress.roadAddress) {
        message.info(`${firstAddress.roadAddress}로 이동합니다`, 2);
      } else if (firstAddress.jibunAddress) {
        message.info(`${firstAddress.jibunAddress}로 이동합니다`, 2);
      }

      map.flyTo({
        center: [firstAddress.x, firstAddress.y],
        essential: true,
      });
    }
  }

  return (
    <div className="mapboxgl-ctrl-top-left">
      <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
        <Input
          className="search-input-address"
          prefix={<SearchOutlined />}
          onPressEnter={(e) => seachAndMove(e.target.value)}
        />
      </div>
    </div>
  );
}

export default SearchControl;
