import React, { createContext, useState } from 'react';

const DrawContext = createContext();

const DrawProvider = (props) => {
  const [drawContext, setDrawContext] = useState({
    size: undefined,
    geometry: undefined,
    field_code: undefined,
  });

  return (
    <DrawContext.Provider
      value={{ ...drawContext, setDrawContext: (data) => setDrawContext({ ...drawContext, ...data }) }}
    >
      {props.children}
    </DrawContext.Provider>
  );
};

export { DrawContext, DrawProvider };
