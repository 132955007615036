import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider, message } from 'antd';
import koKR from 'antd/es/locale/ko_KR';
import App from './App';
import { AppProvider } from './context/AppContext';
import * as serviceWorker from './serviceWorker';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './util/polyfill';

import 'antd/dist/antd.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import './css/antd-custom.css';
import './css/utilities.css';
import './css/style.css';

message.config({
  top: 44,
  duration: 2,
  maxCount: 1,
});

ReactDOM.render(
  <BrowserRouter>
    {/* ant design 버그 fix */}
    {/* 버그 메세지 findDOMNode was passed an instance of LazyRenderBox which is inside StrictMode */}
    {/* <React.StrictMode> */}
    <ConfigProvider locale={koKR}>
      <AppProvider>
        <App />
      </AppProvider>
    </ConfigProvider>
    {/* </React.StrictMode> */}
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
