import React, { useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Col, message, Popconfirm, Row, Tooltip } from 'antd';
import {
  FormOutlined,
  DashboardOutlined,
  DotChartOutlined,
  CameraOutlined,
  AppstoreAddOutlined,
  PoweroffOutlined,
} from '@ant-design/icons';
import axios from '../util/axios';
import { AppContext } from '../context/AppContext';

function MainLayout(props) {
  const history = useHistory();
  const { isTouchDevice, resetCropColors, setMapViewPort } = useContext(AppContext);

  async function logout() {
    resetCropColors();
    setMapViewPort(undefined);
    await axios.get('/api/logout');
    message.info('로그아웃 되었습니다.');
    history.push('/login');
  }

  function menus() {
    const menuStructures = [
      { title: '대쉬보드', link: '/', icon: <DashboardOutlined /> },
      { title: '드론영상', link: '/dronimage', icon: <CameraOutlined /> },
      { title: '필지관리', link: '/field', icon: <AppstoreAddOutlined /> },
      { title: '메모관리', link: '/memo', icon: <FormOutlined /> },
      { title: '보고서', link: '/report', icon: <DotChartOutlined /> },
    ];

    return menuStructures.map((m) => {
      if (isTouchDevice) {
        return (
          <li key={m.title}>
            <NavLink exact to={m.link}>
              {m.icon}
            </NavLink>
          </li>
        );
      }

      return (
        <li key={m.title}>
          <Tooltip placement="right" title={m.title}>
            <NavLink exact to={m.link}>
              {m.icon}
            </NavLink>
          </Tooltip>
        </li>
      );
    });
  }

  return (
    <Row className="full-height full-width">
      <Col flex="60px">
        <ul className="main-menu shadow-right">
          {menus()}
          <li className="logout-btn">
            <Popconfirm
              title="정말로 로그아웃 하시겠습니까?"
              placement="topLeft"
              onConfirm={logout}
              // onCancel={cancel}
              okText="예"
              cancelText="아니오"
            >
              <PoweroffOutlined />
            </Popconfirm>
          </li>
        </ul>
      </Col>
      {props.children}
    </Row>
  );
}

export default MainLayout;
