/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { Result, Button } from 'antd';
import { NavLink } from 'react-router-dom';

function NotFound() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="죄송합니다. 요청하신 페이지를 찾을 수 없습니다."
      extra={
        <NavLink exact to="/">
          메인 페이지로 이동
        </NavLink>
      }
    />
  );
}

export default NotFound;
