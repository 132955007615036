/* eslint-disable no-underscore-dangle */
import mapboxgl from 'mapbox-gl';

import React, { useContext, useEffect, useRef } from 'react';

import SearchControl from './SearchControl';
import LayerControl from './LayerControl';
import { defaultMapStyle, fieldLayerStyle, mapboxApiAccessToken } from './defaults';
import { MemoMapContext } from '../../context/MemoMapContext';
import axios from '../../util/axios';
import { getUserConfigMapViewPort } from '../../util/auth';

import memoSVG from './memo.svg';
import { AppContext } from '../../context/AppContext';
import GovFieldAddressControl from './GovFieldAddressControl';

function MapField() {
  const { mapViewPort, setMapViewPort } = useContext(AppContext);
  const { map, setMemoMapContext } = useContext(MemoMapContext);

  const mapRef = useRef(null);

  async function fetchFields() {
    const response = await axios.get('/api/fields');
    addLayerUserFields(response.data);
  }

  function addMemoImage() {
    const img = new Image(90, 90);
    img.onload = () =>
      map.addImage('memo', img, {
        sdf: 'true',
      });
    img.src = memoSVG;
  }

  function addLayerUserFields(_fields) {
    map.addSource('user_fields', { type: 'geojson', data: _fields });
    map.addLayer({ ...fieldLayerStyle.user, id: 'user_fields_fills' });
    map.addLayer({ ...fieldLayerStyle.userline, id: 'user_fields_lines' });
    map.addLayer({ ...fieldLayerStyle.userlabel, id: 'user_fields_labels' });
  }

  async function initializeMap() {
    mapboxgl.accessToken = mapboxApiAccessToken;
    const m = new mapboxgl.Map({
      container: mapRef.current,
      style: defaultMapStyle,
      ...(mapViewPort || getUserConfigMapViewPort()),
    });

    m.on('load', () => {
      setMemoMapContext({ map: m });
    });

    m.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right');
    m.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      }),
      'bottom-right'
    );
  }

  function onMoveEnd() {
    const viewPort = {
      center: map.getCenter(),
      zoom: map.getZoom(),
    };

    setMapViewPort(viewPort);
  }

  useEffect(() => {
    if (!map) {
      initializeMap();
    }

    if (map) {
      fetchFields();
      addMemoImage();
      map.on('moveend', onMoveEnd);
    }

    return () => {
      if (map) {
        map.off('moveend', onMoveEnd);
      }
    };
  }, [map]);

  return (
    <>
      <div className="map-container" ref={mapRef}>
        <SearchControl map={map} />
        <LayerControl map={map} />
        <GovFieldAddressControl map={map} />
      </div>
    </>
  );
}

export default MapField;
