/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import mapboxgl from 'mapbox-gl';

import React, { useContext, useEffect, useRef, useState } from 'react';

import { Button, Col, List, Row } from 'antd';
import { CloseOutlined, SplitCellsOutlined } from '@ant-design/icons';
import SearchControl from './SearchControl';
import LayerControl from './LayerControl';
import {
  defaultMapStyle,
  defaultDroneimageLayerStyle,
  defaultDroneimageSourceStyle,
  mapboxApiAccessToken,
  fieldLayerStyle,
} from './defaults';
import { formatForImage } from '../../util/etc';
import { fitBounds } from './helper';
import { AppContext } from '../../context/AppContext';
import GovFieldAddressControl from './GovFieldAddressControl';
import { getUsername, getUserConfigMapViewPort, getUserMapDroneImageBounds } from '../../util/auth';

function MapDroneimage({ tilemaps, fields, compare, updateCompare, main }) {
  const { mapViewPort, setMapViewPort } = useContext(AppContext);

  const mapRef = useRef(null);
  const [map, setMap] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedImageType, setSelectedImageType] = useState('NDVI');
  const viewPortInitialized = useRef(false);

  function onMoveEnd() {
    const viewPort = {
      center: map.getCenter(),
      zoom: map.getZoom(),
      pitch: map.getPitch(),
      bearing: map.getBearing(),
      by: main !== undefined ? 1 : 2,
    };

    setMapViewPort(viewPort);
  }

  function initializeMap() {
    mapboxgl.accessToken = mapboxApiAccessToken;
    const m = new mapboxgl.Map({
      container: mapRef.current,
      style: defaultMapStyle,
      ...(mapViewPort || getUserConfigMapViewPort()),
    });

    m.on('load', () => {
      setMap(m);
    });

    m.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right');
    m.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      }),
      'bottom-right'
    );
  }

  function addLayerUserFields() {
    map.addSource('user_fields', { type: 'geojson', data: fields });
    map.addLayer({ ...fieldLayerStyle.user, id: 'user_fields_fills' });
    map.addLayer({ ...fieldLayerStyle.userline, id: 'user_fields_lines' });
    map.addLayer({ ...fieldLayerStyle.userlabel, id: 'user_fields_labels' });

    let hoveredUserFieldId = null;

    map.on('mousemove', 'user_fields_fills', (e) => {
      if (e.features.length > 0) {
        if (hoveredUserFieldId) {
          map.setFeatureState({ source: 'user_fields', id: hoveredUserFieldId }, { hover: false });
        }
        hoveredUserFieldId = e.features[0].id;
        map.setFeatureState({ source: 'user_fields', id: hoveredUserFieldId }, { hover: true });
      }
    });

    map.on('mouseenter', 'user_fields_fills', () => {
      map.getCanvas().style.cursor = 'pointer';
    });

    map.on('mouseleave', 'user_fields_fills', () => {
      map.getCanvas().style.cursor = '';
      if (hoveredUserFieldId) {
        map.setFeatureState({ source: 'user_fields', id: hoveredUserFieldId }, { hover: false });
      }
      hoveredUserFieldId = null;
    });

    map.on('click', 'user_fields_fills', (e) => {
      if (e.features.length > 0) {
        fitBounds(e.features[0].geometry.coordinates[0], map);
      }
    });
  }

  function isRemoteMap() {
    return mapViewPort.by !== (main !== undefined ? 1 : 2);
  }

  function hasViewPortGap() {
    const center = map.getCenter();
    const zoom = map.getZoom();
    const pitch = map.getPitch();
    const bearing = map.getBearing();

    if (mapViewPort.center.lat !== center.lat) return true;
    if (mapViewPort.center.lng !== center.lng) return true;
    if (mapViewPort.zoom !== zoom) return true;
    if (mapViewPort.pitch !== pitch) return true;
    if (mapViewPort.bearing !== bearing) return true;

    return false;
  }

  useEffect(() => {
    if (map) {
      if (viewPortInitialized.current === false) {
        map.on('moveend', onMoveEnd);
        viewPortInitialized.current = true;
      }

      if (mapViewPort && isRemoteMap() && hasViewPortGap()) {
        map.off('moveend', onMoveEnd);
        map.jumpTo(mapViewPort);
        map.on('moveend', onMoveEnd);
      }
    }
  }, [map, mapViewPort]);

  useEffect(() => {
    if (map && fields) {
      addLayerUserFields();
    }
  }, [map, fields]);

  useEffect(() => {
    if (!map) {
      initializeMap();
    }

    return () => {
      if (map) {
        map.remove();
      }
    };
  }, [map]);

  useEffect(() => {
    if (map) {
      map.resize();
    }
  }, [compare]);

  useEffect(() => {
    if (tilemaps) {
      setSelectedDate(tilemaps?.ndvi?.[0]);
    }
  }, [tilemaps]);

  useEffect(() => {
    if (map && tilemaps && selectedDate && selectedImageType) {
      const targetId = selectedImageType;
      const unTargetId = selectedImageType === 'NDVI' ? 'RGB' : 'NDVI';

      const targetDate = selectedDate.replace(/-/g, '');

      if (map.getLayer(targetId)) map.removeLayer(targetId);
      if (map.getSource(targetId)) map.removeSource(targetId);
      if (map.getLayer(unTargetId)) map.removeLayer(unTargetId);
      if (map.getSource(unTargetId)) map.removeSource(unTargetId);

      map.addSource(targetId, {
        ...defaultDroneimageSourceStyle,
        bounds: getUserMapDroneImageBounds(),
        tiles: [`api/tilemap/${getUsername()}/${targetDate}/${targetId.toLowerCase()}/{z}/{x}/{y}.png`],
      });

      map.addLayer(
        { ...defaultDroneimageLayerStyle, id: targetId, source: targetId },
        map.getLayer('user_fields_fills') ? 'user_fields_fills' : undefined
      );
    }
  }, [map, tilemaps, selectedDate, selectedImageType]);

  function onImageTypeChange(imageType) {
    setSelectedImageType(imageType);
  }

  return (
    <>
      <Col flex="150px">
        <div className="layout">
          <div className="header bb-1">
            <Row>
              <Col offset={7} span={10} className="header-col">
                <h1>{main ? '촬영' : '비교'}</h1>
              </Col>
              <Col span={7} className="header-col">
                <Button type="link" size="large" className="header-btn" onClick={() => updateCompare(!compare)}>
                  {main ? compare === false ? <SplitCellsOutlined /> : <></> : <CloseOutlined />}
                </Button>
              </Col>
            </Row>
          </div>
          <div className="main">
            <List
              size="default"
              className="image-list"
              dataSource={tilemaps ? tilemaps[selectedImageType.toLowerCase()] : []}
              renderItem={(date) => (
                <List.Item onClick={() => setSelectedDate(date)} className={selectedDate === date ? 'active' : ''}>
                  <Row className="full-width">
                    <Col span={24} className="text-center">
                      {formatForImage(date)}
                    </Col>
                  </Row>
                </List.Item>
              )}
            />
          </div>
          <div className="footer" />
        </div>
      </Col>
      <Col flex="auto">
        <div className="map-container" ref={mapRef}>
          <SearchControl map={map} />
          <LayerControl
            map={map}
            useDronimageControl
            selectedImageType={selectedImageType}
            onImageTypeChange={onImageTypeChange}
          />
          <GovFieldAddressControl map={map} />
        </div>
      </Col>
    </>
  );
}

export default MapDroneimage;
