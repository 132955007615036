/* eslint-disable react/jsx-wrap-multilines */

import { Button, Col, Row, Input, Popconfirm, Form, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import dayjs from 'dayjs';

import React, { useContext, useEffect } from 'react';
import shortid from 'shortid';
import DatePicker from '../../components/DatePicker';
import { AppContext } from '../../context/AppContext';

const formFieldLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};

function CropRotationModal({
  modalVisible,
  setModalVisible,
  selectedCropRotation,
  setSelectedCropRotation,
  updateCropRotation,
}) {
  const [form] = Form.useForm();
  const { crops } = useContext(AppContext);

  function saveCropRotationOk(values) {
    updateCropRotation({
      ...values,
      harvest_date: values.harvest_date.format('YYYY-MM-DD'),
      seeding_date: values.seeding_date.format('YYYY-MM-DD'),
    });
    setModalVisible(false);
    setSelectedCropRotation(undefined);
  }

  function saveCropRotationCancel() {
    setModalVisible(false);
    setSelectedCropRotation(undefined);
  }

  function deleteCropRotationOk() {
    updateCropRotation({ ...selectedCropRotation, status: 'delete' });
    setModalVisible(false);
    setSelectedCropRotation(undefined);
  }

  function deleteCropRotationCancel() {}

  useEffect(() => {
    if (modalVisible) {
      if (selectedCropRotation === undefined) {
        form.setFieldsValue({
          id: undefined,
          key: shortid.generate(),
          status: 'insert',
          year: dayjs().year(),
          cropping: '전작',
          crop: crops[0],
          seeding_date: dayjs(),
          harvest_date: dayjs(),
          output: '',
        });
      } else {
        form.setFieldsValue({
          id: selectedCropRotation.id,
          key: selectedCropRotation.key,
          status: 'update',
          year: selectedCropRotation.year,
          cropping: selectedCropRotation.cropping,
          crop: selectedCropRotation.crop,
          seeding_date: dayjs(selectedCropRotation.seeding_date),
          harvest_date: dayjs(selectedCropRotation.harvest_date),
          output: selectedCropRotation.output,
        });
      }
    }
  }, [modalVisible, form, selectedCropRotation, crops]);

  const years = [...Array(10).keys()].map((x) => dayjs().year() - x);

  return (
    <Modal
      title="Crop Rotation"
      visible={modalVisible}
      onOk={saveCropRotationOk}
      onCancel={saveCropRotationCancel}
      footer={
        <Row>
          <Col flex="1 1 0" className="p-3">
            <Button type="default" block onClick={saveCropRotationCancel}>
              취소
            </Button>
          </Col>
          <Col flex="3 1 0" className="p-3">
            <Button type="primary" block form="crop-rotaion-form" htmlType="submit">
              저장
            </Button>
          </Col>
          {selectedCropRotation && (
            <Col flex="1 1 0" className="p-3">
              <Popconfirm
                title="정말로 삭제하시겠습니까?"
                onConfirm={deleteCropRotationOk}
                onCancel={deleteCropRotationCancel}
                okText="예"
                cancelText="취소"
              >
                <Button type="danger" block>
                  삭제
                </Button>
              </Popconfirm>
            </Col>
          )}
        </Row>
      }
    >
      <Form id="crop-rotaion-form" {...formFieldLayout} form={form} colon={false} onFinish={saveCropRotationOk}>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="key" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="status" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="year" label="년도" rules={[{ required: true }]}>
          <Select>
            {years.map((year) => (
              <Select.Option key={year} value={year}>
                {year}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="cropping" label="작부" rules={[{ required: true }]}>
          <Select>
            <Select.Option value="전작">전작</Select.Option>
            <Select.Option value="후작">후작</Select.Option>
            <Select.Option value="일모작">일모작</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="crop" label="작물" rules={[{ required: true }]}>
          <Select>
            {crops.map((name) => (
              <Select.Option key={name} value={name}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="seeding_date" label="파종일" rules={[{ required: true }]}>
          <DatePicker />
        </Form.Item>
        <Form.Item name="harvest_date" label="수확일" rules={[{ required: true }]}>
          <DatePicker />
        </Form.Item>
        <Form.Item name="output" label="생산량">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CropRotationModal;
