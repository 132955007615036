/* eslint-disable react/jsx-one-expression-per-line */
import { Button, Col, Input, List, Row } from 'antd';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { centerScreenByLatLng } from '../../components/mapbox/helper';
import MemoListItem from '../../components/MemoListItem';
import { MemoMapContext } from '../../context/MemoMapContext';
import axios from '../../util/axios';
import { debounce, sleep } from '../../util/etc';

function MemoList() {
  const [memos, setMemos] = useState();
  const [searchKeyword, setSearchKeyword] = useState();
  const { selectedMemo, map, setMemoMapContext } = useContext(MemoMapContext);
  const preSelectedMemoId = useRef();
  const memosForSearch = useRef();

  async function fetchMemos() {
    const response = await axios.get('/api/memos');
    setMemos(response.data);
    memosForSearch.current = response.data;
    await sleep(500).then(() => addLayerMemo(response.data));
  }

  function addLayerMemo(_memos) {
    if (map.getLayer('memos')) map.removeLayer('memos');
    if (map.getSource('memos')) map.removeSource('memos');

    map.addSource('memos', { type: 'geojson', data: _memos });
    map.addLayer({
      id: 'memos',
      type: 'symbol',
      source: 'memos',
      layout: {
        'icon-image': 'memo',
        'icon-size': 0.5,
        'icon-offset': [0, -45],
      },
      paint: {
        'icon-color': ['case', ['boolean', ['feature-state', 'selected'], false], '#f76707', '#82c91e'],
      },
    });

    map.on('mouseenter', 'memos', () => {
      map.getCanvas().style.cursor = 'pointer';
    });

    map.on('mouseleave', 'memos', () => {
      map.getCanvas().style.cursor = '';
    });

    map.on('click', 'memos', (e) => {
      if (e.features.length === 0) return;
      setMemoMapContext({ selectedMemo: e.features[0] });
      const fieldName = e.features[0].properties.field_name;
      if (fieldName) {
        setSearchKeyword(fieldName);
        search(fieldName);
      } else {
        setSearchKeyword('');
        search('');
      }
    });
  }

  function toggleSelectedMemo() {
    if (preSelectedMemoId.current) {
      map.setFeatureState({ source: 'memos', id: preSelectedMemoId.current }, { selected: false });
    }
    map.setFeatureState({ source: 'memos', id: selectedMemo.id }, { selected: true });
    preSelectedMemoId.current = selectedMemo.id;
  }

  useEffect(() => {
    if (map) {
      fetchMemos();
    }

    return () => {
      if (map) {
        if (map.getLayer('memos')) map.removeLayer('memos');
        if (map.getSource('memos')) map.removeSource('memos');
      }
    };
  }, [map]);

  useEffect(() => {
    if (map && selectedMemo) {
      centerScreenByLatLng([selectedMemo.geometry.coordinates[0], selectedMemo.geometry.coordinates[1]], map);
      toggleSelectedMemo();
    }
  }, [map, selectedMemo]);

  const onSearchInputChange = (e) => {
    setSearchKeyword(e.target.value);
    debouncedSearchInputChange(e.target.value);
  };

  function search(keyword) {
    const searchedMemos = memosForSearch.current.features.filter(
      (m) =>
        m.properties.content.includes(keyword) ||
        m.properties.category.includes(keyword) ||
        m.properties.field_name?.includes(keyword)
    );

    setMemos({ ...memosForSearch.current, features: searchedMemos });
  }

  const debouncedSearchInputChange = useCallback(
    debounce((keyword) => search(keyword), 300),
    []
  );

  return (
    <Col flex="0 0 340px" className="full-height fix-overflow-ellipsis">
      <div className="layout">
        <div className="header">
          <Row>
            <Col offset={5} span={14} className="header-col">
              <h1>메모 리스트</h1>
            </Col>
          </Row>
        </div>
        <div className="search">
          <Input
            className="search-input"
            prefix={<SearchOutlined />}
            placeholder="검색어 입력"
            allowClear
            value={searchKeyword}
            onChange={onSearchInputChange}
          />
        </div>
        <div className="main">
          <List
            size="default"
            className="memo-list"
            dataSource={memos?.features}
            renderItem={(memo) => <MemoListItem memo={memo} />}
          />
        </div>
        <div className="footer p-3">
          <Button
            type="primary"
            block
            onClick={() => {
              setMemoMapContext({ mode: 'save', selectedMemo: undefined });
            }}
          >
            메모 추가
          </Button>
        </div>
      </div>
    </Col>
  );
}

export default MemoList;
