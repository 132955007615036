import React, { createContext, useState } from 'react';

const MemoMapContext = createContext();

const MemoMapProvider = (props) => {
  const [memoMapContext, setMemoMapContext] = useState({
    map: undefined,
    mode: 'list', // list, save
    selectedMemo: undefined,
  });

  return (
    <MemoMapContext.Provider
      value={{
        ...memoMapContext,
        setMemoMapContext: (data) => setMemoMapContext({ ...memoMapContext, ...data }),
      }}
    >
      {props.children}
    </MemoMapContext.Provider>
  );
};

export { MemoMapContext, MemoMapProvider };
