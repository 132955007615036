const mapboxApiAccessToken = 'pk.eyJ1IjoidHVuaXZvIiwiYSI6ImNrZGk2djhsNTAyMmoydXBmZHNxeWgxNHEifQ.eReky9Mlx2UE584IiBSKCw';

// const dronImageBounds = [127.81408731341297, 36.88136597299582, 127.83316943865157, 36.896864436502746]; --> 서버 데이터로 대체 괴산
// const dronImageBounds = [127.15505265524143, 36.93668263094281, 127.28495921244985, 37.07328175459487]; --> 서버 데이터로 대체 안성

const defaultMapStyle = {
  version: 8,
  sources: {
    osm: {
      type: 'raster',
      tiles: ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
      tileSize: 256,
      bounds: [117, 30, 139, 44],
      minzoom: 5,
      maxzoom: 22,
    },
    'mapbox-satellite': {
      type: 'raster',
      tiles: [`https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=${mapboxApiAccessToken}`],
      tileSize: 256,
      bounds: [117, 30, 139, 44],
      minzoom: 5,
      maxzoom: 22,
    },
    'mapbox-terrain': {
      type: 'raster',
      tiles: [
        `https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/{z}/{x}/{y}.png?access_token=${mapboxApiAccessToken}`,
      ],
      tileSize: 256,
      bounds: [117, 30, 139, 44],
      minzoom: 5,
      maxzoom: 22,
    },
    // 'mapbox-terrain': {
    //   type: 'vector',
    //   url: 'mapbox://mapbox.mapbox-terrain-v2',
    // },
    // vworld: {
    //   type: 'raster',
    //   tiles: ['http://xdworld.vworld.kr:8080/2d/Base/service/{z}/{x}/{y}.png'],
    //   tileSize: 256,
    //   bounds: [117, 30, 139, 44],
    //   minzoom: 5,
    //   maxzoom: 19,
    // },
    // NDVI: {
    //   type: 'raster',
    //   // tiles: ['api/tilemap/20200718/ndvi/{z}/{x}/{y}.png'],
    //   tileSize: 256,
    //   bounds: dronImageBounds,
    //   minzoom: 14,
    //   maxzoom: 21,
    // },
    // RGB: {
    //   type: 'raster',
    //   // tiles: ['api/tilemap/20200718/rgb/{z}/{x}/{y}.png'],
    //   tileSize: 256,
    //   bounds: dronImageBounds,
    //   minzoom: 14,
    //   maxzoom: 21,
    // },
  },
  glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
  layers: [
    {
      id: 'osm',
      type: 'raster',
      source: 'osm',
      layout: {
        visibility: 'visible',
      },
    },
    {
      id: 'mapbox-satellite',
      type: 'raster',
      source: 'mapbox-satellite',
      layout: {
        visibility: 'none',
      },
    },
    {
      id: 'mapbox-terrain',
      type: 'raster',
      source: 'mapbox-terrain',
      layout: {
        visibility: 'none',
      },
    },
    // {
    //   id: 'mapbox-terrain',
    //   type: 'line',
    //   source: 'mapbox-terrain',
    //   'source-layer': 'contour',
    //   layout: {
    //     'line-join': 'round',
    //     'line-cap': 'round',
    //     visibility: 'none',
    //   },
    //   paint: {
    //     'line-color': '#ff69b4',
    //     'line-width': 1,
    //   },
    // },
    // {
    //   id: 'vworld',
    //   type: 'raster',
    //   source: 'vworld',
    //   layout: {
    //     visibility: 'visible',
    //   },
    // },
    // {
    //   id: 'NDVI',
    //   type: 'raster',
    //   source: 'NDVI',
    //   layout: {
    //     visibility: 'none',
    //   },
    // },
    // {
    //   id: 'RGB',
    //   type: 'raster',
    //   source: 'RGB',
    //   layout: {
    //     visibility: 'none',
    //   },
    // },
  ],
};

// const defaultViewport = {
//   center: [127.82213132386427, 36.88964496955969],
//   zoom: 15,
// };

const defaultDroneimageSourceStyle = {
  type: 'raster',
  // tiles: ['api/tilemap/20200718/ndvi/{z}/{x}/{y}.png'],
  tileSize: 256,
  // bounds: dronImageBounds,
  minzoom: 14,
  maxzoom: 21,
};

const defaultDroneimageLayerStyle = {
  // id: 'RGB',
  type: 'raster',
  // source: 'RGB',
  layout: {
    visibility: 'visible',
  },
};

const defaultLayerStyle = {
  layout: {
    visibility: 'visible',
  },
  minzoom: 12,
  maxzoom: 24,
};

const gobFieldsFillOpacity = {
  default: ['case', ['boolean', ['feature-state', 'hover'], false], 0.1, 0],
  fieldSelect: ['case', ['boolean', ['feature-state', 'hover'], false], 0.3, 0.15],
};

const fieldLayerStyle = {
  gov: {
    ...defaultLayerStyle,
    source: 'gov_fields',
    type: 'fill',
    paint: {
      'fill-color': ['case', ['boolean', ['feature-state', 'selected'], false], '#00ff00', '#e03131'],
      'fill-opacity': gobFieldsFillOpacity.default,
    },
    layout: {
      visibility: 'visible',
    },
  },
  user: {
    ...defaultLayerStyle,
    source: 'user_fields',
    type: 'fill',
    paint: {
      'fill-outline-color': 'rgba(252, 196, 25, 1.00)',
      'fill-color': ['case', ['boolean', ['feature-state', 'selected'], false], '#00ff00', '#845ef7'],
      'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.0, 0.4],
    },
  },
  userline: {
    ...defaultLayerStyle,
    source: 'user_fields',
    type: 'line',
    paint: {
      'line-color': 'RGBA(47, 101, 166, 1.00)',
      'line-width': 4,
    },
  },
  userlabel: {
    ...defaultLayerStyle,
    source: 'user_fields',
    type: 'symbol',
    layout: {
      'text-field': ['get', 'name'],
      'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      'text-radial-offset': 0,
      'text-size': 12,
      'text-justify': 'auto',
    },
  },
};

export {
  mapboxApiAccessToken,
  defaultDroneimageSourceStyle,
  defaultDroneimageLayerStyle,
  defaultMapStyle,
  // defaultViewport,
  fieldLayerStyle,
  gobFieldsFillOpacity,
};
