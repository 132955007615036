/* eslint-disable no-param-reassign */

import turf from 'turf';
import { Radio } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import { FieldMapContext } from '../../context/FieldMapContext';
import { gobFieldsFillOpacity } from './defaults';
import { centerScreen, fitBounds } from './helper';
import { DrawContext } from '../../context/DrawContext';
import { AppContext } from '../../context/AppContext';

const radioStyle = {
  display: 'block',
  height: '1.7em',
  lineHeight: '1.7em',
  fontSize: '12px',
};

let draw;

function GovFieldSelectControl({ map }) {
  const [fieldSelectMode, setFieldSelectMode] = useState('gov');
  const { mode, selectedField } = useContext(FieldMapContext);
  const { geometry, setDrawContext } = useContext(DrawContext);
  const { govFields, fieldSelectModeRef } = useContext(AppContext);

  function updateDrawArea(_e) {
    const data = draw.getAll();

    if (data.features.length > 0) {
      const polygon = data.features[data.features.length - 1].geometry;
      const size = Number((turf.area(polygon) / 10000).toFixed(2));
      setDrawContext({ size, geometry: { ...polygon }, fieldCode: null });

      if (data.features.length >= 2) {
        draw.delete(data.features[0].id);
      }
    } else {
      setDrawContext({ size: 0, geometry: undefined, fieldCode: null });
    }
  }

  function addDrawControl() {
    if (draw === undefined) {
      draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
        },
        defaultMode: 'draw_polygon',
      });
    }
    map.addControl(draw, 'bottom-right');

    map.on('draw.create', updateDrawArea);
    map.on('draw.delete', updateDrawArea);
    map.on('draw.update', updateDrawArea);
  }

  function removeDrawControl() {
    if (draw) {
      map.removeControl(draw);
      map.off('draw.create', updateDrawArea);
      map.off('draw.delete', updateDrawArea);
      map.off('draw.update', updateDrawArea);
      draw = undefined;
    }
  }

  async function fetchGovFields() {
    map.on('click', 'gov_fields_fills', (e) => {
      if (e.features.length > 0) {
        // 아래 방법을 사용하지 않으면 geometry 데이터가 서버에 보내서 저장할때 에러발생
        const selectedGovField = map.getSource('gov_fields')._data.features.find((f) => f.id === e.features[0].id);

        const polygon = turf.polygon(selectedGovField.geometry.coordinates);
        const size = Number((turf.area(polygon) / 10000).toFixed(2));

        setDrawContext({
          size,
          geometry: { ...selectedGovField.geometry },
          fieldCode: selectedGovField.properties.code,
        });

        setFieldSelectMode('draw');
      }
    });
  }

  useEffect(() => {
    if (map) {
      map.resize();
      if (mode === 'save') {
        if (fieldSelectMode === 'draw') {
          addDrawControl();
          if (map.getLayer('gov_fields_fills')) {
            map.setPaintProperty('gov_fields_fills', 'fill-opacity', gobFieldsFillOpacity.default);
          }

          if (geometry) {
            draw.add(geometry);
            draw.changeMode('simple_select');
            centerScreen(geometry.coordinates[0], map);
          } else if (selectedField) {
            draw.add(selectedField.geometry);
            draw.changeMode('simple_select');
            fitBounds(selectedField.geometry.coordinates[0], map);
          }
        } else {
          removeDrawControl();
          if (map.getLayer('gov_fields_fills')) {
            map.setPaintProperty('gov_fields_fills', 'fill-opacity', gobFieldsFillOpacity.fieldSelect);
          }
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (map.getLayer('gov_fields_fills')) {
          map.setPaintProperty('gov_fields_fills', 'fill-opacity', gobFieldsFillOpacity.default);
        }
      }
    }

    return () => {
      if (map) {
        removeDrawControl();
      }
    };
  }, [map, fieldSelectMode, mode, selectedField]);

  useEffect(() => {
    if (map && govFields) {
      fetchGovFields();
    }
  }, [map, govFields]);

  useEffect(() => {
    if (mode === 'save') {
      if (selectedField) {
        setFieldSelectMode('draw');
      } else {
        setFieldSelectMode('gov');
      }
    }
  }, [mode, selectedField]);

  useEffect(() => {
    fieldSelectModeRef.current = fieldSelectMode;
  }, [fieldSelectMode]);

  return (
    <div className="mapboxgl-ctrl-bottom-left" style={{ display: mode === 'save' ? 'block' : 'none' }}>
      <div className="mapboxgl-ctrl mapboxgl-ctrl-group p-2">
        <Radio.Group
          onChange={(e) => {
            setFieldSelectMode(e.target.value);
          }}
          value={fieldSelectMode}
        >
          <Radio style={radioStyle} value="gov">
            정부 필지 데이터 선택
          </Radio>
          <Radio style={radioStyle} value="draw">
            직접 필지 그리기
          </Radio>
        </Radio.Group>
      </div>
    </div>
  );
}

export default GovFieldSelectControl;
