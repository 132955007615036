import dayjs from 'dayjs';
// eslint-disable-next-line no-unused-vars
import ko from 'dayjs/locale/ko';

dayjs.locale('ko');

function sleep(ms) {
  return new Promise((res) => setTimeout(res, ms));
}

function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function formatForImage(dateString) {
  return dayjs(dateString).format('YYYY-MM-DD (ddd)');
}

function fullTimeFormat(dateString) {
  return dayjs(dateString).format('YYYY-MM-DD HH:mm:ss');
}

function fullTimeAmPmFormat(dateString) {
  return dayjs(dateString).format('YYYY-MM-DD (dd) A hh:mm');
}

export { sleep, debounce, formatForImage, fullTimeFormat, fullTimeAmPmFormat };
