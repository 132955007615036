import mapboxgl from 'mapbox-gl';

function centerScreen(coordinates, map) {
  const bounds = coordinates.reduce((acc, coord) => {
    return acc.extend(coord);
  }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

  map.flyTo({
    center: bounds.getCenter(),
    essential: true,
  });
}

function centerScreenByLatLng(latlng, map) {
  map.flyTo({
    center: latlng,
    essential: true,
  });
}

function fitBounds(coordinates, map) {
  const bounds = coordinates.reduce((acc, coord) => {
    return acc.extend(coord);
  }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

  map.fitBounds(bounds, {
    padding: 200,
  });
}

export { centerScreen, centerScreenByLatLng, fitBounds };
