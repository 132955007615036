/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useState } from 'react';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import { fieldLayerStyle } from './defaults';
import { AppContext } from '../../context/AppContext';

function GovFieldAddressControl({ map }) {
  const [address, setAddress] = useState();
  const { govFields, fieldSelectModeRef } = useContext(AppContext);

  async function attachMouseOverEvent() {
    if (map.getSource('gov_fields')) {
      return;
    }

    map.addSource('gov_fields', { type: 'geojson', data: govFields });
    map.addLayer({ id: 'gov_fields_fills', ...fieldLayerStyle.gov });

    let hoveredGovFieldId = null;

    map.on('mousemove', 'gov_fields_fills', (e) => {
      if (fieldSelectModeRef.current === 'draw') return;

      if (e.features.length > 0) {
        if (hoveredGovFieldId) {
          map.setFeatureState({ source: 'gov_fields', id: hoveredGovFieldId }, { hover: false });
        }
        hoveredGovFieldId = e.features[0].id;
        map.setFeatureState({ source: 'gov_fields', id: hoveredGovFieldId }, { hover: true });
        const { addr } = e.features[0].properties;
        setAddress(addr);
      } else {
        setAddress(undefined);
      }
    });

    map.on('mouseleave', 'gov_fields_fills', () => {
      setAddress(undefined);
      map.setFeatureState({ source: 'gov_fields', id: hoveredGovFieldId }, { hover: false });
    });
  }

  useEffect(() => {
    if (map && govFields) {
      attachMouseOverEvent();
    }
  }, [map, govFields]);

  if (address === undefined) return null;

  return (
    <div className="mapboxgl-ctrl-bottom-right" style={{ marginRight: '40px' }}>
      <div className="mapboxgl-ctrl mapboxgl-ctrl-group p-2 text-right">{address}</div>
    </div>
  );
}

export default GovFieldAddressControl;
