import { Col } from 'antd';
import React, { useContext } from 'react';
import MainLayout from '../../components/MainLayout';
import FieldHistory from './FieldHistory';
import FieldSave from './FieldSave';
import MapField from '../../components/mapbox/MapField';
import { FieldMapContext } from '../../context/FieldMapContext';
import FieldList from './FieldList';
import { DrawProvider } from '../../context/DrawContext';

function Field() {
  const { mode } = useContext(FieldMapContext);

  return (
    <DrawProvider>
      <MainLayout>
        {mode === 'list' && <FieldList />}

        {mode === 'save' && (
          <Col flex="600px" className="full-height">
            <FieldSave />
          </Col>
        )}

        {mode === 'history' && (
          <Col flex="auto" style={{ height: '100vh', overflowY: 'scroll' }}>
            <FieldHistory />
          </Col>
        )}

        {mode !== 'history' && (
          <Col flex="auto">
            <MapField />
          </Col>
        )}
      </MainLayout>
    </DrawProvider>
  );
}

export default Field;
