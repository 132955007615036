import { Checkbox, Divider, Radio } from 'antd';
import React, { useState } from 'react';

const radioStyle = {
  display: 'block',
  height: '1.7em',
  lineHeight: '1.7em',
  fontSize: '12px',
};

const layersMap = [
  { name: '오픈스트리트 맵', id: 'osm' },
  { name: 'mapbox 위성 맵', id: 'mapbox-satellite' },
  { name: 'mapbox 지형 맵', id: 'mapbox-terrain' },
];

const layersImage = ['NDVI', 'RGB'];

function LayerControl(props) {
  const [selectMapValue, setSelectMapValue] = useState('osm');
  const [imageOnOffChecked, setImageOnOffChecked] = useState(true);

  function onChangeSelectedMap(e) {
    const { value: layerId } = e.target;

    setSelectMapValue(layerId);

    layersMap.forEach((layer) => {
      if (layer.id === layerId) {
        props.map.setLayoutProperty(layer.id, 'visibility', 'visible');
      } else {
        props.map.setLayoutProperty(layer.id, 'visibility', 'none');
      }
    });
  }

  function onImageOnOffChange(e) {
    setImageOnOffChecked(e.target.checked);
    props.map.setLayoutProperty(props.selectedImageType, 'visibility', e.target.checked ? 'visible' : 'none');
  }

  function onImageTypeChange(e) {
    const { value: imageType } = e.target;

    setImageOnOffChecked(true);
    props.onImageTypeChange(imageType);
    if (props.map.getLayer(imageType)) {
      props.map.setLayoutProperty(imageType, 'visibility', 'visible');
    }
  }

  function onFieldFillChange(e) {
    if (e.target.checked) {
      props.map.setPaintProperty('user_fields_fills', 'fill-opacity', [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        0.5,
        0.7,
      ]);
    } else {
      props.map.setPaintProperty('user_fields_fills', 'fill-opacity', 0);
    }
  }

  return (
    <div className="mapboxgl-ctrl-top-right">
      <div className="mapboxgl-ctrl mapboxgl-ctrl-group p-2">
        <Radio.Group onChange={(e) => onChangeSelectedMap(e)} value={selectMapValue}>
          {layersMap.map((layer) => (
            <Radio key={layer.id} style={radioStyle} value={layer.id}>
              {layer.name}
            </Radio>
          ))}
        </Radio.Group>
        {props.useDronimageControl && (
          <>
            <Divider className="mt-2 mb-2" />
            <Checkbox onChange={onImageOnOffChange} checked={imageOnOffChecked}>
              <span style={{ fontSize: '12px' }}>드론 이미지</span>
            </Checkbox>
            <br />
            <Radio.Group onChange={(e) => onImageTypeChange(e)} value={props.selectedImageType}>
              {layersImage.map((imageType) => (
                <Radio key={imageType} style={radioStyle} value={imageType}>
                  {imageType}
                </Radio>
              ))}
            </Radio.Group>
            <Divider className="mt-2 mb-2" />
            <Checkbox onChange={onFieldFillChange} defaultChecked>
              <span style={{ fontSize: '12px' }}>필지 색상</span>
            </Checkbox>
          </>
        )}
      </div>
    </div>
  );
}

export default LayerControl;
