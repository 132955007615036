import { Col, List, Row } from 'antd';
import React from 'react';

function ReportListItem({ report, openReport, selectedReport }) {
  return (
    <List.Item
      onClick={() => openReport(report)}
      className={selectedReport?.fileName === report.fileName ? 'active' : ''}
    >
      <Row className="full-width" style={{ paddingLeft: '12px' }}>
        <Col span={24} className="overflow-ellipsis">
          <strong>{report.name}</strong>
          <br />
          <span>{report.date}</span>
        </Col>
      </Row>
    </List.Item>
  );
}

export default ReportListItem;
