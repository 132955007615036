/* eslint-disable react/jsx-one-expression-per-line */
import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Popover, Row, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import FieldShape from '../../components/FieldShape';
import { AppContext } from '../../context/AppContext';
import { FieldMapContext } from '../../context/FieldMapContext';
import axios from '../../util/axios';

function createColumn(year, target, getCropColor) {
  const configs = {
    전반기: {
      colSpan: 2,
      cropping: '전작',
    },
    후반기: {
      colSpan: 0,
      cropping: '후작',
    },
  };

  function popoverContent(cropRotation) {
    return (
      <ul className="crop-rotation-detail">
        <li>
          <strong>년도</strong> {cropRotation.year}
        </li>
        <li>
          <strong>작부</strong> {cropRotation.cropping}
        </li>
        <li>
          <strong>작물</strong> {cropRotation.crop}
        </li>
        <li>
          <strong>파종일</strong> {cropRotation.seeding_date}
        </li>
        <li>
          <strong>수확일</strong> {cropRotation.harvest_date}
        </li>
        <li>
          <strong>생산량</strong> {cropRotation.output}
        </li>
      </ul>
    );
  }

  return {
    title: `${year}`,
    dataIndex: 'id',
    colSpan: configs[target].colSpan,
    render: (_text, field) => {
      const crs = field.crop_rotations.filter((cr) => cr.year === year);

      let colSpan;
      let type;
      if (crs.some((cr) => cr.cropping === '일모작')) {
        type = '일모작';
        colSpan = configs[target].colSpan;
      } else if (crs.some((cr) => cr.cropping === configs[target].cropping)) {
        type = configs[target].cropping;
      } else {
        return <></>;
      }

      const cropRotation = crs.filter((_cr) => _cr.cropping === type)[0];

      return {
        children: (
          <Popover content={popoverContent(cropRotation)}>
            <div>
              <strong>
                <span className="circle" style={{ backgroundColor: getCropColor(cropRotation.crop) }} />
                {cropRotation.crop}
              </strong>
              <br />
              <span>{cropRotation.output}</span>
            </div>
          </Popover>
        ),
        props: {
          colSpan,
        },
      };
    },
  };
}

function FieldHistory() {
  const [fields, setFields] = useState([]);
  const { setFieldMapContext } = useContext(FieldMapContext);
  const { getCropColor } = useContext(AppContext);

  const columns = [
    {
      title: '필지 이력',
      dataIndex: 'name',
      width: '260px',
      render: (_text, field, _index) => (
        <Row className="full-width" style={{ paddingLeft: '0px' }}>
          <Col span={6} style={{ height: `45px` }}>
            <FieldShape field={field} />
          </Col>
          <Col span={18} className="overflow-ellipsis">
            <strong>{field.name}</strong>
            <br />
            <span>{field.size} ha</span>
          </Col>
        </Row>
      ),
    },
    createColumn(2016, '전반기', getCropColor),
    createColumn(2016, '후반기', getCropColor),
    createColumn(2017, '전반기', getCropColor),
    createColumn(2017, '후반기', getCropColor),
    createColumn(2018, '전반기', getCropColor),
    createColumn(2018, '후반기', getCropColor),
    createColumn(2019, '전반기', getCropColor),
    createColumn(2019, '후반기', getCropColor),
    createColumn(2020, '전반기', getCropColor),
    createColumn(2020, '후반기', getCropColor),
  ];

  useEffect(() => {
    async function fetchFieldHistory() {
      const response = await axios.get('/api/fields/crop_rotations');
      const { data } = response;
      setFields(data);
    }

    fetchFieldHistory();

    setFieldMapContext({ map: undefined });
  }, []);

  return (
    <>
      <Table className="field-history" rowKey="id" columns={columns} dataSource={fields} pagination={false} bordered />
      <Button
        type="link"
        size="large"
        className="header-btn"
        style={{ position: 'absolute', left: '206px', top: '0', display: 'block' }}
        onClick={() => setFieldMapContext({ mode: 'list' })}
      >
        <CloseOutlined />
      </Button>
    </>
  );
}

export default FieldHistory;
