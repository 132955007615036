import React, { useContext } from 'react';
import { geoMercator, geoPath } from 'd3-geo';
import { AppContext } from '../context/AppContext';

const svgSize = 45;

function FieldShape({ field }) {
  const { getCropColor } = useContext(AppContext);

  const projection = geoMercator().fitSize([svgSize, svgSize], field.geometry);
  const pathGenerator = geoPath().projection(projection);
  const path = pathGenerator(field.geometry);
  const fillColor = getCropColor(field.properties?.crop);

  return (
    <svg height={svgSize} width={svgSize}>
      <path d={path} fill={fillColor} />
    </svg>
  );
}

export default FieldShape;
