/* eslint-disable react/jsx-one-expression-per-line */
import { SmallDashOutlined } from '@ant-design/icons';
import { Button, Col, List, Row, Tooltip } from 'antd';
import React, { useContext, useEffect, useRef } from 'react';
import { FieldMapContext } from '../context/FieldMapContext';
import FieldShape from './FieldShape';

function FieldListItem({ field }) {
  const { selectedField, setFieldMapContext } = useContext(FieldMapContext);
  const myRef = useRef();

  useEffect(() => {
    if (selectedField?.id === field.id) {
      myRef.current.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
    }
  }, [selectedField, field]);

  return (
    <List.Item
      onClick={() => setFieldMapContext({ selectedField: field })}
      className={selectedField?.id === field.id ? 'active' : ''}
    >
      <Row className="full-width" style={{ paddingLeft: '12px' }} ref={myRef}>
        <Col span={6} style={{ height: `45px` }}>
          <FieldShape field={field} />
        </Col>
        <Col span={14} className="overflow-ellipsis">
          <strong>{field.properties.name}</strong>
          <br />
          <span>
            {field.properties.size} ha
            {field.properties.crop && ` | ${field.properties.crop}`}
          </span>
        </Col>
        <Col span={4} className="text-center">
          <Tooltip placement="right" title="수정">
            <Button
              type="text"
              className="full-height"
              onClick={(e) => {
                setFieldMapContext({ mode: 'save', selectedField: field });
                e.stopPropagation();
              }}
              icon={<SmallDashOutlined />}
            />
          </Tooltip>
        </Col>
      </Row>
    </List.Item>
  );
}

export default FieldListItem;
