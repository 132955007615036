import Cookies from 'js-cookie';

function isAuthenticated() {
  return Cookies.get('username') !== undefined;
}

function logout() {
  Cookies.remove('connect.sid');
  Cookies.remove('username');
  localStorage.removeItem('config');
  window.location.replace('/');
}

function getUsername() {
  return Cookies.get('username');
}

function setUserConfig(config) {
  localStorage.setItem('config', JSON.stringify(config));
}

function getUserConfig() {
  if (localStorage.getItem('config') === null) {
    logout();
    return;
  }

  return JSON.parse(localStorage.getItem('config'));
}

function getUserConfigMapViewPort() {
  let userConfig = getUserConfig();

  return {
    center: userConfig.map.center,
    zoom: userConfig.map.zoom,
  };
}

function getUserMapDroneImageBounds() {
  let userConfig = getUserConfig();

  return userConfig.map.dronImageBounds;
}

export {
  isAuthenticated,
  logout,
  getUsername,
  setUserConfig,
  getUserConfig,
  getUserConfigMapViewPort,
  getUserMapDroneImageBounds,
};
