const distinguishColors = {
  red: '#e6194b',
  green: '#3cb44b',
  yellow: '#ffe119',
  blue: '#4363d8',
  orange: '#f58231',
  purple: '#911eb4',
  cyan: '#42d4f4',
  magenta: '#f032e6',
  lime: '#bcf60c',
  pink: '#fabebe',
  teal: '#008080',
  lavender: '#e6beff',
  brown: '#9a6324',
  beige: '#fffac8',
  maroon: '#800000',
  mint: '#aaffc3',
  olive: '#808000',
  apricot: '#ffd8b1',
  navy: '#000075',
  gray: '#808080',
  white: '#ffffff',
  black: '#000000',
};

export default distinguishColors;
