import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from './page/Login';
import Dashboard from './page/Dashboard';
import Droneimage from './page/Droneimage';
import Field from './page/Field/Field';
import Memo from './page/Memo/Memo';
import Report from './page/Report';
import ProtectedRoute from './components/ProtectedRoute';
import NotFound from './components/NotFound';
import { FieldMapProvider } from './context/FieldMapContext';
import { MemoMapProvider } from './context/MemoMapContext';

function App() {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <ProtectedRoute exact path="/" component={Dashboard} />
      <ProtectedRoute exact path="/dronimage" component={Droneimage} />
      <ProtectedRoute exact path="/field" component={Field} provider={FieldMapProvider} />
      <ProtectedRoute exact path="/memo" component={Memo} provider={MemoMapProvider} />
      <ProtectedRoute exact path="/report" component={Report} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
}

export default App;
