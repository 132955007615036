/* eslint-disable no-underscore-dangle */
import { CloseOutlined, HistoryOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Row, List, Input } from 'antd';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import axios from '../../util/axios';
import FieldListItem from '../../components/FieldListItem';
import { fieldLayerStyle } from '../../components/mapbox/defaults';
import { FieldMapContext } from '../../context/FieldMapContext';
import { centerScreen } from '../../components/mapbox/helper';
import { debounce } from '../../util/etc';
import { getUsername } from '../../util/auth';

function FieldList() {
  const [fields, setFields] = useState([]);
  const { selectedField, map, setFieldMapContext } = useContext(FieldMapContext);
  const preSelectedFieldId = useRef();
  const fieldsForSearch = useRef();

  function toggleSelectedUserField(fieldId) {
    if (preSelectedFieldId.current) {
      map.setFeatureState({ source: 'user_fields', id: preSelectedFieldId.current }, { selected: false });
    }
    preSelectedFieldId.current = fieldId;
    map.setFeatureState({ source: 'user_fields', id: fieldId }, { selected: true });
  }

  function addLayerUserFields(_fields) {
    map.addSource('user_fields', { type: 'geojson', data: _fields });
    map.addLayer({ ...fieldLayerStyle.user, id: 'user_fields_fills' });
    map.addLayer({ ...fieldLayerStyle.userline, id: 'user_fields_lines' });
    map.addLayer({ ...fieldLayerStyle.userlabel, id: 'user_fields_labels' });

    let hoveredUserFieldId = null;

    map.on('mousemove', 'user_fields_fills', (e) => {
      if (e.features.length > 0) {
        if (hoveredUserFieldId) {
          map.setFeatureState({ source: 'user_fields', id: hoveredUserFieldId }, { hover: false });
        }
        hoveredUserFieldId = e.features[0].id;
        map.setFeatureState({ source: 'user_fields', id: hoveredUserFieldId }, { hover: true });
      }
    });

    map.on('mouseenter', 'user_fields_fills', () => {
      map.getCanvas().style.cursor = 'pointer';
    });

    map.on('mouseleave', 'user_fields_fills', () => {
      map.getCanvas().style.cursor = '';
      if (hoveredUserFieldId) {
        map.setFeatureState({ source: 'user_fields', id: hoveredUserFieldId }, { hover: false });
      }
      hoveredUserFieldId = null;
    });

    map.on('click', 'user_fields_fills', (e) => {
      if (e.features.length > 0) {
        setFieldMapContext({
          selectedField: map.getSource('user_fields')._data.features.find((f) => f.id === e.features[0].id),
        });
      }
    });
  }

  async function fetchFields() {
    const response = await axios.get('/api/fields');
    setFields(response.data);
    addLayerUserFields(response.data);
    fieldsForSearch.current = response.data;
  }

  useEffect(() => {
    if (map) {
      map.resize();
    }

    if (selectedField) {
      centerScreen(selectedField.geometry.coordinates[0], map);
      toggleSelectedUserField(selectedField.id);
    }
  }, [map, selectedField]);

  useEffect(() => {
    if (map) {
      fetchFields();
    }

    return () => {
      if (map) {
        if (map.getLayer('user_fields_fills')) map.removeLayer('user_fields_fills');
        if (map.getLayer('user_fields_lines')) map.removeLayer('user_fields_lines');
        if (map.getLayer('user_fields_labels')) map.removeLayer('user_fields_labels');
        if (map.getSource('user_fields')) map.removeSource('user_fields');
      }
    };
  }, [map]);

  const onSearchInputChange = (e) => {
    debouncedSearchInputChange(e.target.value);
  };

  function search(keyword) {
    const searchedMemos = fieldsForSearch.current.features.filter(
      (m) =>
        m.properties.name.includes(keyword) ||
        m.properties.field_code?.includes(keyword) ||
        m.properties.crop?.includes(keyword)
    );

    setFields({ ...fieldsForSearch.current, features: searchedMemos });
  }

  const debouncedSearchInputChange = useCallback(
    debounce((keyword) => search(keyword), 300),
    []
  );

  return (
    <>
      <Col flex="260px" className={`full-height ${selectedField ? 'shadow-right' : ''}`}>
        <div className="layout">
          <div className="header">
            <Row>
              <Col offset={5} span={14} className="header-col">
                <h1>필지 리스트</h1>
              </Col>
              <Col span={5} className="header-col">
                <Button
                  type="link"
                  size="large"
                  className="header-btn"
                  onClick={() => setFieldMapContext({ mode: 'history', selectedField: undefined })}
                >
                  <HistoryOutlined />
                </Button>
              </Col>
            </Row>
          </div>
          <div className="search">
            <Input
              className="search-input"
              prefix={<SearchOutlined />}
              placeholder="검색어 입력"
              allowClear
              onChange={onSearchInputChange}
            />
          </div>
          <div className="main">
            <List
              size="default"
              className="field-list"
              dataSource={fields.features}
              renderItem={(field) => <FieldListItem field={field} />}
            />
          </div>
          <div className="footer p-3">
            <Button
              type="primary"
              block
              onClick={() => {
                setFieldMapContext({ mode: 'save', selectedField: undefined });
              }}
            >
              필지 추가
            </Button>
          </div>
        </div>
      </Col>
      {selectedField !== undefined && (
        <Col flex="400px" className="full-height">
          <div className="layout">
            <div className="header">
              <Row>
                <Col offset={3} span={18} className="header-col">
                  <h1>{selectedField.properties.name}</h1>
                </Col>
                <Col span={3} className="header-col">
                  <Button
                    type="link"
                    size="large"
                    className="header-btn"
                    onClick={() => setFieldMapContext({ selectedField: undefined })}
                  >
                    <CloseOutlined />
                  </Button>
                </Col>
              </Row>
            </div>
            <div className="main" style={{ overflowY: 'hidden' }}>
              <iframe
                src={`/shiny/field/${getUsername()}/?field_id=${selectedField.id}`}
                title="dashboard"
                width="100%"
                height="100%"
                frameBorder="0"
              />
            </div>
          </div>
        </Col>
      )}
    </>
  );
}

export default FieldList;
