/* eslint-disable react/jsx-one-expression-per-line */

import { Button, Col, message, Row, Table, Input, Popconfirm, Form, InputNumber } from 'antd';
import dayjs from 'dayjs';
import React, { useState, useEffect, useContext } from 'react';
import shortid from 'shortid';
import { DrawContext } from '../../context/DrawContext';
import { FieldMapContext } from '../../context/FieldMapContext';
import axios from '../../util/axios';
import CropRotationModal from './CropRotationModal';

const formFieldLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const columns = [
  {
    title: '년도',
    dataIndex: 'year',
    align: 'center',
    sorter: (a, b) => a.year - b.year,
    defaultSortOrder: 'descend',
  },
  {
    title: '작부',
    dataIndex: 'cropping',
    align: 'center',
    sorter: (a, b) => a.cropping.localeCompare(b.cropping),
  },
  {
    title: '작물',
    dataIndex: 'crop',
    align: 'center',
    sorter: (a, b) => a.crop.localeCompare(b.crop),
  },
  {
    title: '파종일',
    dataIndex: 'seeding_date',
    align: 'center',
    sorter: (a, b) => dayjs(a.seeding_date) - dayjs(b.seeding_date),
  },
  {
    title: '수확일',
    dataIndex: 'harvest_date',
    align: 'center',
    sorter: (a, b) => dayjs(a.harvest_date) - dayjs(b.harvest_date),
  },
  {
    title: '생산량',
    dataIndex: 'output',
    align: 'center',
  },
];

function FieldSave() {
  const [modalVisible, setModalVisible] = useState(false);
  const [field, setField] = useState();
  const [cropRotations, setCropRotations] = useState([]);
  const [selectedCropRotation, setSelectedCropRotation] = useState();
  const [form] = Form.useForm();
  const { selectedField, setFieldMapContext } = useContext(FieldMapContext);
  const { size, geometry, fieldCode, setDrawContext } = useContext(DrawContext);

  function setModeToList() {
    setFieldMapContext({ mode: 'list', selectedField: undefined });
    setDrawContext({ size: undefined, geometry: undefined, fieldCode: undefined });
  }

  async function saveFieldOk(values) {
    const fieldFormData = {
      field: { id: selectedField?.id, ...values, ...(size && { size, geometry, field_code: fieldCode }) },
      cropRotations,
    };

    const response = await axios.post('/api/field', fieldFormData);

    if (response.data.ok) {
      message.success('저장 완료');
      setModeToList();
    } else {
      message.error('저장 실패');
    }
  }

  function saveFieldCancel() {
    setModeToList();
    form.resetFields();
  }

  async function deleteFieldOk() {
    const response = await axios.delete(`/api/field/${field.id}`);
    if (response.data.ok) {
      message.success(`${field.name} 삭제 완료`);
      setModeToList();
    } else {
      message.error(response.data.message, 4);
    }
  }

  function deleteFieldCancel() {}

  function updateCropRotation(cropRotation) {
    // 기존에 있었던 데이터라면
    if (cropRotations.some((cr) => cr.key === cropRotation.key)) {
      setCropRotations(cropRotations.map((item) => (item.key === cropRotation.key ? cropRotation : item)));
    } else {
      setCropRotations([...cropRotations, cropRotation]);
    }
  }

  useEffect(() => {
    async function fetchField() {
      const response = await axios.get(`/api/field/${selectedField.id}`);
      const { data } = response;
      setField(data);
      setCropRotations(data.crop_rotations.map((cr) => ({ ...cr, key: shortid.generate() })));
      form.setFieldsValue(data);
    }
    if (selectedField) {
      fetchField();
    }
  }, [form, selectedField]);

  useEffect(() => {
    if (size !== undefined) {
      form.setFieldsValue({ size });
    }
    form.setFieldsValue({ field_code: fieldCode });
  }, [form, size, fieldCode]);

  return (
    <Form {...formFieldLayout} form={form} colon={false} onFinish={saveFieldOk} className="field-save">
      <div className="layout">
        <div className="header">
          <Row>
            <Col offset={5} span={14} className="header-col">
              <h1>필지 {selectedField ? '수정' : '추가'}</h1>
            </Col>
            <Col span={5} className="header-col" />
          </Row>
        </div>
        <div className="main p-3">
          <Form.Item name="name" label="필지명" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item
            name="size"
            label="필지크기"
            rules={[
              {
                validator(_, value) {
                  if (value !== undefined && value > 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject('필지를 우측 지도에서 생성해야 합니다.');
                },
              },
            ]}
          >
            <InputNumber disabled style={{ width: '100%' }} formatter={(value) => `${value} ha`} />
          </Form.Item>

          <Form.Item name="field_code" label="필지코드">
            <Input disabled />
          </Form.Item>

          <Table
            bordered
            dataSource={cropRotations.filter((cr) => cr.status !== 'delete')}
            columns={columns}
            pagination={false}
            size="small"
            rowClassName="cursor-pointer"
            onRow={(record) => {
              return {
                onClick: () => {
                  setSelectedCropRotation(record);
                  setModalVisible(true);
                },
              };
            }}
          />
          <div className="pt-3">
            <Button onClick={() => setModalVisible(true)} type="primary" block>
              추가
            </Button>
          </div>
          <CropRotationModal
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            selectedCropRotation={selectedCropRotation}
            setSelectedCropRotation={setSelectedCropRotation}
            updateCropRotation={updateCropRotation}
          />
        </div>
        <div className="footer">
          <Form.Item wrapperCol={{ offset: 0, span: 24 }} className="mb-0">
            <Row>
              <Col flex="1 1 0" className="p-3">
                <Button type="default" block onClick={saveFieldCancel}>
                  취소
                </Button>
              </Col>
              <Col flex="3 1 0" className="p-3">
                <Button type="primary" block htmlType="submit">
                  저장
                </Button>
              </Col>
              {selectedField && (
                <Col flex="1 1 0" className="p-3">
                  <Popconfirm
                    title="정말로 삭제하시겠습니까?"
                    onConfirm={deleteFieldOk}
                    onCancel={deleteFieldCancel}
                    okText="예"
                    cancelText="취소"
                  >
                    <Button type="danger" block>
                      삭제
                    </Button>
                  </Popconfirm>
                </Col>
              )}
            </Row>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
}

export default FieldSave;
