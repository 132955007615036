import { Col } from 'antd';
import React, { useContext } from 'react';
import MainLayout from '../../components/MainLayout';
import MapMemo from '../../components/mapbox/MapMemo';
import { MemoMapContext } from '../../context/MemoMapContext';
import MemoList from './MemoList';
import MemoSave from './MemoSave';

function Memo() {
  const { mode } = useContext(MemoMapContext);

  return (
    <MainLayout>
      {mode === 'list' && <MemoList />}

      {mode === 'save' && <MemoSave />}

      <Col flex="auto">
        <MapMemo />
      </Col>
    </MainLayout>
  );
}

export default Memo;
