import React, { useContext } from 'react';
import { Form, Row, Col, Typography, Input, Button, message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import axios from '../util/axios';
import { isAuthenticated, setUserConfig } from '../util/auth';
import { AppContext } from '../context/AppContext';

const { Title } = Typography;

function Login(props) {
  const { fetchCropColors } = useContext(AppContext);

  if (isAuthenticated()) {
    props.history.push('/');
  }

  async function login(values) {
    try {
      const res = await axios.post('/api/login', values);
      setUserConfig(res.data.config);
      fetchCropColors();
      message.success('로그인 되었습니다.');
      props.history.push('/');
    } catch (error) {
      message.warn('로그인정보가 일치하지 않습니다.');
    }
  }

  return (
    <Row style={{ height: '100vh' }} type="flex" align="middle">
      <Col xs={6} sm={10} md={12} lg={14} xl={16} className="login-background shadow-right" />
      <Col xs={18} sm={14} md={12} lg={10} xl={8} className="p-5">
        <Title level={2} className="text-center keep-word">
          노지 스마트농업
          <br />
          드론 영상분석 서비스
        </Title>

        <Form onFinish={(values) => login(values)} className="mt-5">
          <Form.Item name="username">
            <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="아이디" autoFocus />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: '패스워드를 입력하십시오.' }]}>
            <Input
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="패스워드"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              로그인
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default Login;
