import React, { createContext, useEffect, useRef, useState } from 'react';
import distinguishColors from '../util/distinguishColors';
import axios from '../util/axios';

const AppContext = createContext();

const AppProvider = (props) => {
  const [cropColors, setCropColors] = useState();
  const [memoCategories, setMemoCategories] = useState();
  const [crops, setCrops] = useState();
  const [mapViewPort, setMapViewPort] = useState();
  const [govFields, setGovFields] = useState();
  const [isTouchDevice, setIsTouchDevice] = useState();
  const fieldSelectModeRef = useRef();

  function getCropColor(cropName) {
    if (cropColors === undefined) return;

    const cropColor = cropColors.find((c) => c.name === cropName);
    if (cropColor) {
      return cropColor.color;
    }
    return distinguishColors.gray;
  }

  function resetCropColors() {
    setCropColors(undefined);
  }

  async function fetchCropColors() {
    if (cropColors) return;

    const res = await axios.get('/api/crop_colors');
    if (res.data) {
      setCropColors(res.data);
      setCrops(res.data.map((c) => c.name));
    }
  }

  useEffect(() => {
    async function fetchMemoCategories() {
      if (memoCategories) return;

      const res = await axios.get('/api/memo_categories');
      setMemoCategories(res.data);
    }

    async function fetchGovFields() {
      if (govFields) return;

      const res = await axios.get('/api/gov/fields.geojson');
      setGovFields(res.data);
    }

    function checkIsTouchDevice() {
      const prefixes = ['', '-webkit-', '-moz-', '-o-', '-ms-', ''];
      const mq = (query) => window.matchMedia(query).matches;

      if ('ontouchstart' in window || (window.DocumentTouch && document instanceof window.DocumentTouch)) {
        return true;
      }
      return mq(['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(''));
    }

    fetchCropColors();
    fetchMemoCategories();
    fetchGovFields();
    setIsTouchDevice(checkIsTouchDevice());
  }, []);

  return (
    <AppContext.Provider
      value={{
        crops,
        getCropColor,
        fetchCropColors,
        resetCropColors,
        memoCategories,
        mapViewPort,
        setMapViewPort,
        govFields,
        fieldSelectModeRef,
        isTouchDevice,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
