import axios from 'axios';

// const UNAUTHORIZED = 401;
const FORBIDDEN = 403;

const instance = axios.create();

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status === FORBIDDEN) {
      alert('비정상적 접근');
      window.location = '/login';
    }
    return Promise.reject(error);
  }
);

export default instance;
