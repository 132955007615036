import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../util/auth';

const ProtectedRoute = ({ component: Component, provider: Provider, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated()) {
          if (Provider) {
            return (
              <Provider>
                <Component {...props} />
              </Provider>
            );
          }
          return <Component {...props} />;
        }
        return <Redirect to="/login" />;
      }}
    />
  );
};

export default ProtectedRoute;
