/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-one-expression-per-line */
import { AudioOutlined, FileImageOutlined, SmallDashOutlined } from '@ant-design/icons';
import { Button, Col, Image, List, Row, Tooltip } from 'antd';
import React, { useContext, useEffect, useRef } from 'react';
import { MemoMapContext } from '../context/MemoMapContext';
import { fullTimeAmPmFormat } from '../util/etc';

function MemoListItem({ memo }) {
  const { selectedMemo, setMemoMapContext } = useContext(MemoMapContext);
  const myRef = useRef();
  const isActive = selectedMemo?.id === memo.id;

  useEffect(() => {
    if (isActive) {
      myRef.current.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
    }
  }, [selectedMemo, memo]);

  function getFieldNameOrLocation() {
    if (memo.properties.field_name) {
      return <strong>{memo.properties.field_name}</strong>;
    }
    return (
      <span>
        {memo.geometry.coordinates[0].toFixed(7)}, {memo.geometry.coordinates[1].toFixed(7)}
      </span>
    );
  }

  return (
    <List.Item onClick={() => setMemoMapContext({ selectedMemo: memo })} className={isActive ? 'active' : ''}>
      <Row className="full-width" style={{ paddingLeft: '12px' }} ref={myRef}>
        <Col span={20}>
          <Row>
            <Col span={24} className="color-secondary">
              {fullTimeAmPmFormat(memo.properties.event_at)}
            </Col>
            <Col span={24} className="color-secondary overflow-ellipsis">
              {getFieldNameOrLocation()}
            </Col>
            <Col span={24} className={`text-bold mt-1 content ${isActive ? '' : 'overflow-ellipsis-second-line'}`}>
              [{memo.properties.category}]{' '}
              {!isActive && memo.properties.files.length > 0 && (
                <>
                  {' '}
                  <FileImageOutlined />{' '}
                </>
              )}
              {!isActive && memo.properties.audio && (
                <>
                  {' '}
                  <AudioOutlined />{' '}
                </>
              )}
              {memo.properties.content}
            </Col>
            {isActive && memo.properties.audio && (
              <audio controls controlsList="nodownload">
                <source src={memo.properties.audio.url} type="audio/mp3" />
              </audio>
            )}
            {isActive && memo.properties.files.length > 0 && (
              <Col span={24} className="text-bold">
                {memo.properties.files &&
                  memo.properties.files.map((f) => <Image key={f.uid} className="memo-image-small" src={f.url} />)}
              </Col>
            )}
          </Row>
        </Col>
        <Col span={4} className="text-center">
          <Tooltip placement="right" title="수정">
            <Button
              type="text"
              className="full-height"
              onClick={(e) => {
                setMemoMapContext({ mode: 'save', selectedMemo: memo });
                e.stopPropagation();
              }}
              icon={<SmallDashOutlined />}
            />
          </Tooltip>
        </Col>
      </Row>
    </List.Item>
  );
}

export default MemoListItem;
