import { CaretRightOutlined, DeleteOutlined, PauseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, message, Popconfirm, Row, Select, Upload } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useContext, useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import dayjs from 'dayjs';
import { ReactMic } from '@cleandersonlobo/react-mic';
import { MemoMapContext } from '../../context/MemoMapContext';
import axios from '../../util/axios';
import { centerScreenByLatLng } from '../../components/mapbox/helper';
import { AppContext } from '../../context/AppContext';

const formFieldLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

function MemoSave() {
  let markerMemo;
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [previewTitle, setPreviewTitle] = useState(false);

  const [form] = Form.useForm();
  const { memoCategories } = useContext(AppContext);
  const { selectedMemo, map, setMemoMapContext } = useContext(MemoMapContext);
  const [fileList, setFileList] = useState(selectedMemo?.properties?.files ?? []);
  const [removedFileList, setRemovedFileList] = useState([]);

  const [isRecording, setIsRecording] = useState(false);
  const [audio, setAudio] = useState();
  const [audioFile, setAudioFile] = useState();
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const audioPlayer = useRef();

  async function saveMemoOk(values) {
    const response = await axios.post('/api/memo', createFormData(values));

    if (response.data.ok) {
      message.success('저장 완료');
      setMemoMapContext({ mode: 'list', selectedMemo: undefined });
    } else {
      message.error('저장 실패');
    }
  }

  function saveMemoCancel() {
    setMemoMapContext({ mode: 'list', selectedMemo: undefined });
    form.resetFields();
  }

  async function deleteMemoOk() {
    const response = await axios.delete(`/api/memo/${selectedMemo.id}`);
    if (response.data.ok) {
      message.success(`메모 삭제 완료`);
      setMemoMapContext({ mode: 'list', selectedMemo: undefined });
    } else {
      message.error('삭제 실패');
    }
  }

  function addMarker() {
    const markerLatLng = selectedMemo
      ? new mapboxgl.LngLat(selectedMemo.geometry.coordinates[0], selectedMemo.geometry.coordinates[1])
      : map.getCenter();

    markerMemo = new mapboxgl.Marker({
      draggable: true,
    })
      .setLngLat(markerLatLng)
      .addTo(map);

    const latlng = `${markerLatLng.lat.toFixed(7)}, ${markerLatLng.lng.toFixed(7)}`;
    const screenCenter = map.project(markerLatLng);
    const f = map.queryRenderedFeatures(screenCenter, { layers: ['user_fields_fills'] });

    if (f.length > 0) {
      form.setFieldsValue({ latlng, field_name: f[0].properties.name, field_id: f[0].id });
    } else {
      form.setFieldsValue({ latlng, field_name: undefined, field_id: undefined });
    }

    centerScreenByLatLng(markerLatLng, map);

    function onDragEnd() {
      const lngLat = markerMemo.getLngLat();
      form.setFieldsValue({ latlng: `${lngLat.lat.toFixed(7)}, ${lngLat.lng.toFixed(7)}` });
      const point = map.project(lngLat);

      const features = map.queryRenderedFeatures(point, { layers: ['user_fields_fills'] });

      if (features.length > 0) {
        form.setFieldsValue({ field_name: features[0].properties.name, field_id: features[0].id });
      } else {
        form.setFieldsValue({ field_name: undefined, field_id: undefined });
      }
    }

    markerMemo.on('dragend', onDragEnd);
  }

  useEffect(() => {
    if (form) {
      if (selectedMemo) {
        form.setFieldsValue({ ...selectedMemo.properties, event_at: dayjs(selectedMemo.properties.event_at) });
        setAudio(selectedMemo.properties.audio?.url);
      } else {
        form.setFieldsValue({ event_at: dayjs(), content: '', category: memoCategories[0].name });
      }
    }
  }, [form, selectedMemo]);

  useEffect(() => {
    if (map && form) {
      addMarker();
    }
    return () => {
      if (map) {
        markerMemo.remove();
      }
    };
  }, [map, form]);

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  function createFormData(values) {
    const formData = new FormData();

    if (selectedMemo) formData.append('id', selectedMemo.id);
    if (values.field_id) formData.append('field_id', values.field_id);
    formData.append('event_at', values.event_at);
    formData.append('latlng', values.latlng);
    formData.append('category', values.category);
    formData.append('content', values.content);
    removedFileList.forEach((file) => {
      formData.append('removedFiles', file);
    });
    fileList.forEach((file) => {
      formData.append('images', file);
    });

    if (audioFile) {
      formData.append('audio', audioFile, 'audio.mp3');
    }
    return formData;
  }

  function onRecordingStop(recordedBlob) {
    setAudioFile(recordedBlob.blob);
    setAudio(URL.createObjectURL(recordedBlob.blob));
    setIsRecording(false);
  }

  function onPauseAudio() {
    setIsAudioPlaying(false);
  }

  function onDeleteAudio() {
    setAudioFile(undefined);
    setAudio(undefined);
    setRemovedFileList([...removedFileList, selectedMemo.properties.audio.uid]);
    audioPlayer.current = undefined;
  }

  function playAudio() {
    audioPlayer.current = new Audio(audio);
    audioPlayer.current.play();
    audioPlayer.current.onpause = onPauseAudio;
    setIsAudioPlaying(true);
  }

  function stopAudio() {
    audioPlayer.current.pause();
    setIsAudioPlaying(false);
  }

  let recordAndPlay;

  if (audio) {
    recordAndPlay = isAudioPlaying ? (
      <>
        <Button onClick={() => stopAudio()}>
          <PauseOutlined />
        </Button>
      </>
    ) : (
      <>
        <Button onClick={() => playAudio()}>
          <CaretRightOutlined />
        </Button>
        <Button onClick={onDeleteAudio} className="ml-5">
          <DeleteOutlined />
        </Button>
      </>
    );
  } else {
    recordAndPlay = (
      <>
        <Button onClick={() => setIsRecording(!isRecording)}>{isRecording ? '녹음 정지' : '녹음 시작'}</Button>
      </>
    );
  }

  return (
    <Col flex="340px" className="full-height">
      <Form {...formFieldLayout} form={form} colon={false} onFinish={saveMemoOk}>
        <div className="layout">
          <div className="header">
            <Row>
              <Col offset={5} span={14} className="header-col">
                <h1>
                  메모
                  {selectedMemo ? ' 수정' : ' 추가'}
                </h1>
              </Col>
            </Row>
          </div>
          <div className="main">
            <Form.Item name="event_at" label="날짜" className="pr-3 mb-2 mt-3" rules={[{ required: true }]}>
              <DatePicker showTime format="YYYY-MM-DD HH:mm" className="full-width" />
            </Form.Item>
            <Form.Item name="field_name" label="필지" className="pr-3 mb-2">
              <Input disabled />
            </Form.Item>
            <Form.Item name="field_id" label="필지" className="pr-3 mb-2" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="latlng" label="위도경도" className="pr-3 mb-2" rules={[{ required: true }]}>
              <Input disabled />
            </Form.Item>
            <Form.Item name="category" label="유형" className="pr-3 mb-2" rules={[{ required: true }]}>
              <Select>
                {memoCategories.map((category) => (
                  <Select.Option key={category.name} value={category.name}>
                    {category.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="content" label="내용" className="pr-3 mb-2">
              <Input.TextArea rows={7} />
            </Form.Item>
            <ReactMic record={isRecording} className="recorder" onStop={onRecordingStop} mimeType="audio/mp3" />
            <Form.Item label="음성녹음" className="pr-3 mb-2">
              {recordAndPlay}
            </Form.Item>
            <Form.Item label="이미지" className="pr-3 mb-2">
              <Upload
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onRemove={(file) => {
                  setFileList([...fileList.filter((f) => f.uid !== file.uid)]);
                  setRemovedFileList([...removedFileList, file.uid]);
                }}
                beforeUpload={(file, newFileList) => {
                  setFileList([...fileList, ...newFileList]);
                  return false;
                }}
                multiple
                accept=".jpg,.jpeg,.png,.mp3"
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              </Upload>
              <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
              </Modal>
            </Form.Item>
          </div>
          <div className="footer">
            <Form.Item wrapperCol={{ offset: 0, span: 24 }} className="mb-0">
              <Row>
                <Col flex="1 1 0" className="p-3">
                  <Button type="default" block onClick={saveMemoCancel}>
                    취소
                  </Button>
                </Col>
                <Col flex="3 1 0" className="p-3">
                  <Button type="primary" block htmlType="submit">
                    저장
                  </Button>
                </Col>
                {selectedMemo && (
                  <Col flex="1 1 0" className="p-3">
                    <Popconfirm title="정말로 삭제하시겠습니까?" onConfirm={deleteMemoOk} okText="예" cancelText="취소">
                      <Button type="danger" block>
                        삭제
                      </Button>
                    </Popconfirm>
                  </Col>
                )}
              </Row>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Col>
  );
}

export default MemoSave;
